import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import SubmitButton from './formComponents/SubmitButton';
import LogoLoader from './formComponents/LogoLoader';
import ColorPicker from './formComponents/ColorPicker';
import InputNumber from './formComponents/InputNumber';
import { PhoneInput } from './formComponents/MaskedInput';

const ThemeSettingsForm = props => {
    const { t } = useTranslation();

    const onChangeLogo = () => {
        props.form.submit();
    }

    const { isAdmin, isFunctionalLicense } = props;

    return <div className='about-company-wrapper'>
        { !(isFunctionalLicense && !isAdmin) &&
            <Fragment>
                <Field
                    name='logo'
                    component={LogoLoader}
                    label={t('settings.logo')}
                    onChange={onChangeLogo}
                    disableClear />
                <Field
                    name='theme.vacancyColor'
                    component={ColorPicker}
                    label={t('settings.color')} />
                <Row gutter={16}>
                    <Col sm={12} xs={24}>
                        <Field
                            name='theme.phone'
                            component={PhoneInput}
                            label={t('settings.phone')} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12} xs={24}>
                        <Field
                            name='maxVacancyDurationDays'
                            component={InputNumber}
                            label={t('settings.maxVacancyDurationDays')} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12} xs={24}>
                        <Field
                            name='notificationDays'
                            component={InputNumber}
                            label={t('settings.notificationDays')} />
                    </Col>
                </Row>
            </Fragment>
        }
        <div className='modal-form-toolbar'>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </div>
    </div>
}

const validationSchema = yup.object().shape({
    theme: yup.object().shape({
        phone: yup.string().phone().nullable()
    })
});

export default withUserCompany(withFormWrapper(ThemeSettingsForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        pdaLabel: item.pdaLabel || 'opd',
        theme: item.theme || {}
    }),
    validationSchema
}));
