import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { withStateHandlers } from 'recompose';
import styled from 'styled-components';
import { any, compose, path, prop, max, isNil, propEq, find, findIndex, toLower, omit, pathOr, isEmpty, is } from 'ramda';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, FormSpy, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';
import moment from 'moment';

import withFormWrapper from '../../hocs/withFormWrapper';
import withUserCompany from '../../hocs/withUserCompany';
import { URL_CODE } from '../../../constants/regexps';
import { validateFields, serverValidateFields } from '../../../constants/vacancy';
import VacancyFields from './VacancyFields';
import GeneralVacancyFields from './GeneralVacancyFields';
import Questions from './Questions';
import ListenerField from '../ListenerField';
import { addCompanyCode, getFormQuestions, getVacancyCode } from '../../../utils/vacancy';
import { CHANGE_LANDING, OPEN_TERMINAL_MODAL, OPEN_VACANCY_LANDING_MODAL } from '../../../constants/actionTypes';
import { useTranslation, withTranslation } from 'react-i18next';
import { getCityDictionaryHandler, getDictionaryHandler, getVacancyChartsHandler, putVacancyLandingsHandler } from '../../../actions/handlers';
import UserContext from '../../../contexts/UserContext';
import { LANDING, VACANCY, VACANCY_CATEGORIES } from '../../../constants/urls';
import axios from 'axios';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import DialogVacancyFields from './DialogVacancyFields';
import Landings from '../../views/landing/Landings';
import LanguageDropdown from '../../layout/guest/LanguageDropdown';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const ContentWrapper = styled.div`
    display: ${({ active }) => active ? 'block' : 'none'};
`;

const Controls = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
`;

const ControlButton = styled(Button)`
    margin-right: 15px;
`;

const HeadGroup = styled.div`
    margin: 15px -15px 10px;
    background: #f5f4f4;
    color: #444c63;
    font-size: 15px;
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    font-weight: 500;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;

    .ant-btn {
        margin-top: 10px;
        float: right;
    }
`;

const isLast = step => step === 1;

const mapValues = (values, props) => omit(['questionsSelectType', 'publish'], {
    ...values,
    terminalPublished: !!values.terminalPublished,
    sliderPublished: !!values.sliderPublished,
    botPublished: !!values.botPublished,
    questions: values.questions && values.questions.length ? values.questions : getFormQuestions(),
    published: values.domains && values.domains.length && (!values.landingPublished && !values.botPublished && !values.terminalPublished) ? true : values.published,
    responsibleBy: values.responsibleBy || props.userId,
    code: addCompanyCode(path(['code'], values), props.companyCode),
    address: path(['address', 'address'], values),
    location: path(['address', 'location'], values),
});

const VacancyMasterForm = props => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const [putPending, setPutPending] = useState(false);
    const [loadCities, setLoadCities] = useState(null);
    const { addAction, removeAction, addOnSuccessFn, removeOnSuccessFn, revalidateAction } = useContext(ActionsContext);
    const didMount = useRef(null);
    const vacanciesCategories = useCompanyCodeSwr(VACANCY_CATEGORIES.stringify(), url => axios.get(url, {
        params: {
            pagination: { limit: 0 }
        }
    }));
    const vacancyId = useField('id', props.form);
    const landings = useCompanyCodeSwr([LANDING.stringify(), vacancyId.input.value], (url, vacancy) => axios.get(url, {
        params: {
            pagination: { limit: 0 },
            filter: { vacancy, published: true }
        }
    }), { revalidateOnMount: !!vacancyId.input.value });
    const modals = useContext(ModalsContext);

    const onLandingChange = () => !props.landingSettingsChanged && props.setLandingSettingsChanged(true);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            addAction(LANDING.stringify(), landings);
            addOnSuccessFn(CHANGE_LANDING, onLandingChange);

            const handleDictionaryField = (field, handler, filters = {}, onSuccess) => {
                if (field) {
                    handler({
                        payload: {
                            ...filters,
                            value: field,
                        }
                    }).then(data => onSuccess(field, data));
                }
            };

            const dictionarySuccessHandler = (field, name = 'name', value = 'id', isArray, isFirst) => (requestValue, payload) => {
                if (requestValue || isFirst) {
                    const id = compose(
                        prop(value),
                        find(item => isFirst ? true : toLower(prop(name, item) || '') === toLower(requestValue || ''))
                    )(payload.items);

                    props.form.change(field, isArray ? [id] : id);

                    if (field === 'cities') {
                        setLoadCities([{ id, name: requestValue }]);
                    }
                }
            };

            window.loadVacancyFields = (locale, data) => {
                const formData = JSON.parse(data);
                const headers = { 'Accept-Language': locale };

                handleDictionaryField(formData.city, getCityDictionaryHandler, {
                    filter: {
                        name: formData.city,
                    },
                    headers,
                    pagination: {
                        offset: 0,
                        limit: null,
                    },
                }, dictionarySuccessHandler('cities', 'name', 'id', true));
                handleDictionaryField(formData.chartOfWork, getVacancyChartsHandler, {
                    headers
                }, dictionarySuccessHandler('chartOfWork'));
                handleDictionaryField(formData.employeeType, getDictionaryHandler, {
                    filter: { type: 'PrjEmployType' }, headers
                }, dictionarySuccessHandler('employeeType'));
                handleDictionaryField(formData.educationLevel, getDictionaryHandler, {
                    filter: { type: 'PplEduLevel' }, headers
                }, dictionarySuccessHandler('educationLevel'));
                handleDictionaryField(formData.salaryCurrency, getDictionaryHandler, {
                    filter: { type: 'currency' }, headers
                }, dictionarySuccessHandler('salaryCurrency', 'code', 'id'));

                const isRangeSalary = formData.minSalary && formData.maxSalary;
                const isFixedSalary = !formData.minSalary && formData.maxSalary;
                handleDictionaryField(formData.salaryType || (isRangeSalary || isFixedSalary), getDictionaryHandler, {
                    filter: { type: 'SalaryType' }, headers
                }, dictionarySuccessHandler('salaryType', 'text', 'id', false, true));

                props.form.batch(() => {
                    props.form.change('title', formData.title);
                    props.form.change('code', formData.code);
                    props.form.change('description', formData.description);
                    if (formData.address) {
                        props.form.change('address', {
                            address: `${formData.address}`,
                        });
                    }
                    if (isRangeSalary) {
                        props.form.change('minSalary', formData.minSalary);
                        props.form.change('maxSalary', formData.maxSalary);
                    }
                    if (isFixedSalary) {
                        props.form.change('salaryViewType', 'fixed');
                        props.form.change('maxSalary', formData.maxSalary);
                    }
                });

                return 'ok';
            };

            window.initForm = (data, locale) => {
                window.loadVacancyFields(locale, data);
            };
        }
    });

    useEffect(() => {
        addAction(LANDING.stringify(), landings);
    }, [vacancyId.input.value, pathOr(0, ['data', 'items', 'length'], landings)]);

    useEffect(() => {
        return () => {
            removeAction(LANDING.stringify());
            removeOnSuccessFn(CHANGE_LANDING);
            delete window.loadVacancyFields;
            delete window.initForm;
        }
    }, []);

    const getQuestionsFields = () => {
        return <FieldArray name='questions'>
            { fieldProps =>
                <Questions
                    {...fieldProps}
                    form={props.form}
                    highlightError={props.highlightError}
                    edit={props.edit}
                    newVacancy={props.newVacancy}
                    vacancy={props.vacancy}
                    isMaster
                />
            }
        </FieldArray>;
    }

    const setFormData = (type, data) => {
        props.form.initialize({
            ...data,
            address: is(Object, data.address) ? data.address : {
                address: data.address,
                location: data.location
            },
            publish: props.edit ? (data.terminalPublished || data.sliderPublished || data.botPublished || props.publishedLanding || !isEmpty(data.landings || [])) : !data.published
        });

        switch(type) {
            case 'bot':
                !props.botSettingsChanged && props.setBotSettingsChanged(true);
                return;
            case 'terminal':
                !props.terminalSettingsChanged && props.setTerminalSettingsChanged(true);
                return;
            default:
                return;
        }
    };

    const getInfoStep = ({ values }) => {
        const {
            companySettings,
            isAdmin,
            isFunctionalLicense
        } = props;
        const { publicationSettings = {} } = user;

        const category = path(['name'], find(propEq('id', values.category), pathOr([], ['data', 'items'], vacanciesCategories)));
        const publishedBot = values.botPublished;
        const publishedTerminal = values.terminalPublished;
        const publishedLanding = values.landingPublished;

        return <div className='vacancy-block_description'>
            <div>
                { publishedTerminal && (
                    <HeadGroup>
                        { t('vacancy.form.willPublishedInTerminal') }{ category ? `: ${category}` : '' }
                        { !(isFunctionalLicense && !isAdmin) &&
                            <Button disabled={!prop('terminal', publicationSettings)} onClick={() => modals.open(OPEN_TERMINAL_MODAL, { vacancy: values, companySettings, setFormData: values => setFormData('terminal', values), edit: true })}>
                                { t('vacancy.form.settingsForTerminal') }
                            </Button>
                        }
                    </HeadGroup>
                )}
                { publishedBot &&
                    <Fragment>
                        <HeadGroup>
                            { t('vacancy.form.publishedInChatBot') }
                        </HeadGroup>
                        <DialogVacancyFields vacancy={values} t={t} isMaster />
                    </Fragment>
                }
                { publishedLanding &&
                    <Fragment>
                        <HeadGroup>
                            { t('vacancy.form.publishedInLandings') }
                            <Button disabled={!prop('landing', publicationSettings)} onClick={() => modals.open(OPEN_VACANCY_LANDING_MODAL, { id: values.id, title: values.title, pda: props.companySettings.pda })}>
                                { t('vacancy.form.landingsDesignAddress') }
                            </Button>
                        </HeadGroup>
                        <Landings vacancy={values.id} vacancyTitle={values.title} pda={props.companySettings.pda} staticList hideToolbar />
                    </Fragment>
                }
            </div>
        </div>;
    }

    const getSteps = publish => {
        const { isFullLicense, isAdmin, isFunctionalLicense } = props;
        const fullLicense = isFullLicense || isAdmin;
        const onlyDomains = !prop('bot', user.publicationSettings) && !prop('landing', user.publicationSettings) && !prop('terminal', user.publicationSettings);

        return [
            {
                content: <Fragment>
                    <VacancyFields {...props} fullLicense={fullLicense} validateOnChange />
                    <Field name='domains'>
                        { ({ input: { value : domains } }) => (
                            <ListenerField listenFieldName={['botPublished', 'terminalPublished']}>
                                { ({ botPublished, terminalPublished }) => (
                                    <div className={(domains && domains.length) || terminalPublished || botPublished ? '' : 'hide'}>
                                        <GeneralVacancyFields {...props} loadCities={loadCities} validateOnChange />
                                    </div>
                                )}
                            </ListenerField>
                        )}
                    </Field>
                </Fragment>,
                validateFields: validateFields.general,
                onSave: publish ? save : complete,
            },
            (fullLicense || isFunctionalLicense) && !onlyDomains && { content: (
                <FormSpy subscription={{ values: true }}>
                    { (form) => (
                        <Fragment>
                            { getInfoStep(form) }
                            <br />
                            <br />
                            { getQuestionsFields() }
                        </Fragment>
                    )}
                </FormSpy>
            ), validateFields: validateFields.form, onSave: complete },
        ].filter(Boolean);
    }

    const putVacancy = values => {
        const { next, history, location, edit, onComplete, step, form, setStep } = props;

        setPutPending(true);
        putVacancyLandingsHandler(values, path(['data', 'items'], landings)).then(data => {
            const vacancy = path([0, 0], data);

            setPutPending(false);
            revalidateAction(VACANCY.stringify());
            revalidateAction(`/edit${VACANCY.stringify()}`);

            if (edit) {
                const onlyDomains = !prop('bot', user.publicationSettings) && !prop('landing', user.publicationSettings) && !prop('terminal', user.publicationSettings);
                const values = form.getState().values;
                const publish = values.botPublished || values.terminalPublished || values.landingPublished;
                message.success(t('vacancy.form.successSave'));

                if (publish && !isLast(step) && !onlyDomains) {
                    next();
                } else {
                    onComplete && onComplete();
                }
            } else {
                if (vacancy.published) {
                    history.replace(location.pathname.replace('create', vacancy.id));
                    message.success(t('vacancy.form.successPublished'));
                } else {
                    next();
                }
            }
        }).catch(err => {
            setPutPending(false);
            goToInvalidStep(err, setStep);
        });
    }

    const save = (e, values, errors) => {
        const { validateFields } = getSteps()[props.step];

        if (hasErrors(errors, validateFields)) {
            props.setDisplayError();
            scrollToError();
        } else {
            if (values.id) {
                putVacancy(mapValues(values, props));
            } else {
                props.handleSubmit(e);
            }
        }
    }

    const complete = (e, values, errors) => {
        if (values.id) {
            putVacancy(mapValues({
                ...values,
                published: true
            }, props));
        } else {
            const { validateFields } = getSteps()[props.step];

            if (hasErrors(errors, validateFields)) {
                props.setDisplayError();
                scrollToError();
            } else {
                props.handleSubmit(e);
            }
        }
    }

    const next = errors => {
        const { validateFields } = getSteps()[props.step];

        if (hasErrors(errors, validateFields)) {
            props.setDisplayError();
            scrollToError();
        } else {
            props.next();
        }
    }

    const hasErrors = (errors, validateFields) => {
        return validateFields && any(field => field && path(field.split('.'), errors), validateFields);
    }

    const scrollToError = () => {
        setTimeout(() => {
            const field = document.querySelector('.ant-form-item-has-error');

            if (field) {
                field.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }

    const renderSteps = publish => {
        const { step, previous, pending, vacancy, botSettingsChanged, landingSettingsChanged, terminalSettingsChanged, edit } = props;
        const steps = getSteps(publish);
        const data = steps[step];
        const lastIndex = steps.length - 1;
        const noLandingdSteps = !publish && step === 0;
        const last = step === lastIndex;

        return <Fragment>
            { steps.map((item, index) =>
                <ContentWrapper key={`step-content-${index}`} active={index === step}>
                    { item.content }
                </ContentWrapper>
            )}
            <Controls>
                { step > 0 && (
                    <ControlButton disabled={pending || putPending} onClick={() => previous()}>
                        { t('vacancy.form.back') }
                    </ControlButton>
                )}
                <FormSpy subscription={{ values: true, errors: true }}>
                    { ({ errors, values }) => {
                        const publishedBot = (botSettingsChanged || path(['botPublished'], values)) && values.botPublished;
                        const publishedTerminal = (terminalSettingsChanged || path(['terminalPublished'], values)) && values.terminalPublished;
                        const landingPublished = (landingSettingsChanged || path(['landingPublished'], values)) && values.landingPublished;
                        const publishedLanding = landingPublished && !!pathOr([], ['data', 'items'], landings).length;

                        return <Button
                            onClick={data.onSave ? e => data.onSave(e, values, errors) : () => next(errors)}
                            type='primary'
                            loading={pending || putPending}
                            disabled={
                                (step === 0 && (!(publishedBot || publishedTerminal || landingPublished) && !(values.domains || []).length)) ||
                                (isLast(step) && (!(publishedBot || publishedTerminal || publishedLanding) || !(values.questions || []).length))
                            }
                        >
                            { (last || (!vacancy || edit) && noLandingdSteps) ? (vacancy ? t('form.save') : t('vacancy.form.publish')) : t('vacancy.form.next') }
                        </Button>
                    }}
                </FormSpy>
            </Controls>
        </Fragment>;
    }

    const { step, isExperiumForm, vacancy, edit } = props;

    return <Fragment>
        { (!edit || isExperiumForm) &&
            <div className='toolbar vacancy-add-toolbar'>
                { isExperiumForm && (
                    <Helmet>
                        <title>Form Ready</title>
                    </Helmet>
                )}
                { isExperiumForm && (
                    <div className='toolbar-language-dropdown'>
                        <LanguageDropdown disableLanguageReload />
                    </div>
                )}
                <ListenerField listenFieldName={'title'}>
                    {({ title }) => vacancy ? <h1>{vacancy.title}</h1> : <h1>{ t('vacancy.form.publishOfVacancy') } { step > 0 ? `(${ title })` : '' }</h1>}
                </ListenerField>
            </div>
        }
        <div className='vacancy-add-block'>
            <ListenerField listenFieldName={['botPublished', 'terminalPublished', 'landingPublished']}>
                { ({ botPublished, terminalPublished, landingPublished }) => (
                    renderSteps(botPublished || terminalPublished || landingPublished)
                )}
            </ListenerField>
        </div>
    </Fragment>
}

const scrollToTop = () => document.querySelector('.ant-layout-content').scrollTop = 0;

const withStepsState = withStateHandlers(
    { step: 0, highlightError: false, botSettingsChanged: false, terminalSettingsChanged: false, landingSettingsChanged: false },
    {
        next: ({ step }) => () => {
            scrollToTop();
            return { step: step + 1, highlightError: false };
        },
        previous: ({ step }) => () => {
            scrollToTop();
            return { step: max(0, step - 1), highlightError: false };
        },
        setStep: () => step => ({ step }),
        setDisplayError: () => () => ({ highlightError: true }),
        setBotSettingsChanged: () => botSettingsChanged => ({ botSettingsChanged }),
        setTerminalSettingsChanged: () => terminalSettingsChanged => ({ terminalSettingsChanged }),
        setLandingSettingsChanged: () => landingSettingsChanged => ({ landingSettingsChanged })
    }
);

const validationSchema = props => yup.object().shape({
    title: yup.string().nullable().required(),
    code: yup.string().nullable().code(URL_CODE),
    description: yup.string().nullable().min(6),
    datePublishedStart: yup.string().nullable().required().when('datePublishedEnd', {
        is: val => !((val === null) && !props.companySettings.maxVacancyDurationDays || val),
        then: () => yup.string().matches('error', props.t('vacancy.form.requiredField'))
    }),
    questions: yup.array()
        .nullable()
        .of(
            yup.object().shape({
                question: yup.string().required(),
                label: yup.string().required()
            })
        ),
    salaryViewType: yup.string().nullable().required(),
    minSalary: yup.number().positive().nullable(),
    maxSalary: yup.number().positive().nullable()
        .when(
            ['minSalary', 'domains', 'terminalPublished', 'botPublished'],
            (min, domains, terminalPublished, botPublished) => ((domains && domains.length) || terminalPublished || botPublished) && !isNil(min)
                ? yup.number().nullable().moreThan(yup.ref('minSalary'), props.t('vacancy.form.minSalaryValidation'))
                : yup.number().nullable()
        ),
    salaryType: yup.string().nullable()
        .when(
            ['minSalary', 'maxSalary', 'domains', 'terminalPublished', 'botPublished'],
            (min, max, domains, terminalPublished, botPublished) => ((domains && domains.length) || terminalPublished || botPublished) && (!isNil(max) || !isNil(min))
                ? yup.string().nullable().required()
                : yup.string().nullable()
        ),
    terminalSettings: yup.object().shape({
        showPhoneAllowed: yup.boolean().nullable()
    }),
    companySettings: yup.object().when('terminalSettings.showPhoneAllowed', (show, schema) =>
        show ? schema.shape({
            theme: yup.object().shape({
                phone: yup.string().phone().nullable().required()
            })
        }) : schema
    ),
});

const goToInvalidStep = (error, setStep) => {
    const errors = path(['data', 'errors'], error);

    if (errors) {
        const step = findIndex(fields => any(field => errors[field], fields), serverValidateFields);
        step > -1 && setStep(step);
    }
}

const withPublishedLanding = WrappedComponent => props => {
    const { actions } = useContext(ActionsContext);
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const publishedLanding = !!pathOr([], [LANDING.stringify(), 'data', 'items'], actions).length;

    return <WrappedComponent
        {...props}
        publishedLanding={publishedLanding}
        t={t}
        location={location}
        history={history} />
}

export default withTranslation()(
    withUserCompany(
        withStepsState(
            withPublishedLanding(
                withFormWrapper(
                    VacancyMasterForm,
                    {
                        noErrorRender: true,
                        mapPropsToValues: ({ vacancy, companyId, publishedLanding, edit, companySettings }) => {
                            const maxDate = companySettings.maxVacancyDurationDays ? moment().add(companySettings.maxVacancyDurationDays, 'days') : null;
                            const data = vacancy || {
                                company: companyId,
                            };

                            return {
                                salaryViewType: 'minMax',
                                published: edit ? data.published : false,
                                terminalPublished: data.terminalPublished || data.sliderPublished,
                                landingPublished: publishedLanding || !isEmpty(data.landings || []),
                                ...data,
                                salaryType: data.minSalary || data.maxSalary ? data.salaryType : null,
                                datePublishedStart: edit ? data.datePublishedStart : moment().toISOString(),
                                datePublishedEnd: edit ? data.datePublishedEnd || null : maxDate || data.datePublishedEnd,
                                code: getVacancyCode(path(['code'], data)),
                                questions: path(['questions'], data) || [],
                                address: {
                                    address: path(['address'], data),
                                    location: path(['location'], data),
                                },
                            };
                        },
                        validationSchema,
                        // enableReinitialize: (prev, next) => path(['companySettings', 'maxVacancyDurationDays'], prev) !== path(['companySettings', 'maxVacancyDurationDays'], next),
                        enableReinitialize: () => false,
                        mapBeforeSubmit: (values, props) => mapValues(values, props),
                        onSuccess: ({ next, form, history, location, isExperiumForm, t }, data) => {
                            const vacancy = data[0];

                            form.initialize(vacancy);

                            if (vacancy.published) {
                                if (isExperiumForm) {
                                    history.push(location.pathname.replace('/edit', '').replace('create', vacancy.id));
                                } else {
                                    history.replace(location.pathname.replace('create', vacancy.id));
                                    message.success(t('vacancy.form.successPublished'));
                                }
                            } else {
                                next();
                            }
                        },
                        onSubmitFail: ({ setStep }, e) => goToInvalidStep(e, setStep)
                    }
                )
            )
        )
    )
)
